import { useState } from 'react';
import editDeal from '../../../../api/deals/editDeal';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import {
  DEAL_STATUS_CAR_DELIVERY,
  DEAL_STATUS_COMPLETED,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_HALYK,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
} from '../../../../constants';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import alert from '../../../../helpers/alert';
import setStep from '../../../../api/deals/setStep';
import Button from '../../../../components/Button/Button';
import NotarialDocuments from '../CommonBankSteps/NotarialDocuments';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import PaymentStatus from '../CommonBankSteps/PaymentStatus';
import CarRegistration from '../CommonBankSteps/CarRegistration';
import CarDelivery from '../CommonBankSteps/CarDelivery';
import carRegistration from '../../../../api/cars/registration';
import WaitingForNewBankStatus from '../CommonBankSteps/WaitingForNewBankStatus';
import ContractSign from '../CommonBankSteps/ContractSign';

const BCCFlow = ({ step, deal, documents, update, cars, chosenBankAppealId, bankId }) => {
  const [loading, setLoading] = useState(false);

  const onNotarialDocumentsSuccess = (values) => {
    editDeal({
      id: deal?.id,
      dealId: deal?.id,
      statusId: DEAL_STATUS_WAIRING_REGISTRATION,
      step: DEAL_STEPS.BCK.AUTO_REREGISTRATION,
      power_of_attorney_number: values?.powerOfAttorneyNumber
        ? values?.powerOfAttorneyNumber
        : deal?.power_of_attorney_number,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(() => {
        update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPaymentSubmit = (values) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.BCK.NOTARIAL_VERIFICATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRegistrationSubmit = async (values) => {
    await setStep({ dealId: deal?.id, step: DEAL_STEPS.BCK.CAR_DELIVERY });
    update && update();
  };
  const onReregistrationSubmit = async () => {
    setLoading(true);
    try {
      await carRegistration({
        bankId,
        chunkId: deal?.chunkId,
        carId: cars?.[0]?.id,
        reg_num: '',
      });
      await setStep({ dealId: deal?.id, step: DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP });
      update && update();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const onCarDeliverySubmit = async (values) => {};

  const goBack = (statusId, step) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusId,
      step,
    })
      .then(() => {
        update && update();
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoaderInline />;
  }

  if(step === DEAL_STEPS.BEREKE.SIGN_CONTRACT) {
    return <ContractSign deal={deal} documents={documents} cars={cars} dealStepAfterSign={DEAL_STEPS.BCK.WAITING_FOR_PAYMENT} setLoading={setLoading} update={update}/>;
  }
  if (step === DEAL_STEPS.BCK.WAITING_BANK_ACTIONS) {
    return <WaitingForNewBankStatus bankId={bankId} chunkId={deal?.chunkId} setLoading={setLoading} onSuccess={update} />;
  }

  if (step === DEAL_STEPS.BCK.NOTARIAL_VERIFICATION) {
    return (
        <NotarialDocuments
          documents={documents}
          loading={loading}
          onSuccess={onNotarialDocumentsSuccess}
          deal={deal}
          setLoading={setLoading}
          chosenBankAppealId={chosenBankAppealId}
          bankId={bankId}
        />
    );
  }
  if (step === DEAL_STEPS.BCK.WAITING_FOR_PAYMENT) {
    return (
      <>
        <PaymentStatus
          onSubmit={onPaymentSubmit}
          documents={documents}
          deal={deal}
          chunkId={deal?.chunkId}
          setLoading={setLoading}
          loading={loading}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(deal?.status?.id, DEAL_STEPS.FORTE.SIGN_CONTRACT)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.BCK.AUTO_REREGISTRATION) {
    return (
      <>
        <CarRegistration
          onSuccess={onRegistrationSubmit}
          deal={deal}
          setLoading={setLoading}
          loading={loading}
          statusOption={[
            {
              value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
              label: 'Ожидает постановки ТС на учет',
            },
            {
              value: DEAL_STATUS_CAR_DELIVERY,
              label: 'Машина поставлена на учет',
            },
          ]}
        />
      </>
    );
  }
  if (step === DEAL_STEPS.BCK.CAR_DELIVERY) {
    return <CarDelivery deal={deal} loading={loading} setLoading={setLoading} onSuccess={update}/>;
  }
};

export default BCCFlow;
